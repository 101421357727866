form {
  input {
    @apply
      w-full
      border
      border-primary-gray
      mb-2
      px-2
      py-1
      text-sm
      basic-transition;

    &:focus-visible {
      @apply
        shadow
        outline-none;
    }
  }

  span[role=alert] {
    @apply
      text-white
      bg-red-400
      rounded-b-lg
      text-xs
      -mt-2
      px-2
      py-1
      block;
  }

  label {
    @apply
      text-sm
      text-primary-blue;

    > * {
      @apply
        text-xs;
    }
  }

  .submit {
    @apply
      w-full
      uppercase
      mt-2
      pt-2
      text-white
      bg-primary-orange;

    &:hover {
      @apply
        bg-primary-dorange;
    }
  }
}